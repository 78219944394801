<template>
  <div class="elite-tabs-wrapper-content">
    <h3>{{ salesInvoice.code }}</h3>
    <div class="all-infos">
      <div class="all-infos-text">
        <dl class="row">
          <dt>{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd>
            {{ salesInvoice.created_at | moment("LLLL") }}
          </dd>
        </dl>
        <dl class="row">
          <dt>
            {{ $t("SALES_INVOICES.EXPIRATION_TIME") }}
          </dt>
          <dd>
            {{ salesInvoice.expiration_time | moment("LLLL") }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.LOCATIONS") }}</dt>
          <dd>
            <locations :locations="salesInvoice.allowedLocations" />
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.ORGANIZATION") }}</dt>
          <dd>
            <organization :organization="salesInvoice.organization" />
          </dd>
        </dl>
        <dl class="row" v-if="salesInvoice.salesInvoice">
          <dt>{{ $t("COMMON.SALES_ORDER") }}</dt>
          <dd>
            <object-link :object="salesInvoice.salesInvoice" />
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.RECIPIENT") }}</dt>
          <dd>
            <object-link :object="salesInvoice.recipient" />
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.STATUS") }}</dt>
          <dd>
            <sales-invoice-status-badge :salesInvoice="salesInvoice" />
          </dd>
        </dl>
        <dl class="row" v-if="salesInvoice.pricing">
          <dt>{{ $t("COMMON.TOTAL") }}</dt>
          <dd>
            <span> {{ $formatCurrency(salesInvoice.pricing.total) }} </span>
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.TOTAL_PAIED") }}</dt>
          <dd>
            <span>
              {{
                $formatCurrency(
                  salesInvoice.total_paied ? salesInvoice.total_paied : 0
                )
              }}
            </span>
          </dd>
        </dl>
        <dl class="row" v-if="salesInvoice.excerpt">
          <dt>
            {{ $t("COMMON.NOTE") }}
          </dt>
          <dd>
            <div v-html="salesInvoice.excerpt"></div>
          </dd>
        </dl>
        <div class="col-12 text-center mb-5 mt-5">
          <h2>{{ $t("COMMON.BILLING_ADDRESS") }}</h2>
        </div>
        <dl
          class="row"
          v-if="
            salesInvoice.billing_entity_type === BILLING_ENTITY_TYPE_INDIVIDUAL
          "
        >
          <dt>{{ $t("COMMON.LASTNAME") }}</dt>
          <dd>
            {{ salesInvoice.billing_lastname }}
          </dd>
        </dl>
        <dl
          class="row"
          v-if="
            salesInvoice.billing_entity_type === BILLING_ENTITY_TYPE_INDIVIDUAL
          "
        >
          <dt>{{ $t("COMMON.FIRSTNAME") }}</dt>
          <dd>
            {{ salesInvoice.billing_firstname }}
          </dd>
        </dl>
        <dl
          class="row"
          v-if="
            salesInvoice.billing_entity_type === BILLING_ENTITY_TYPE_COMPANY
          "
        >
          <dt>{{ $t("COMMON.COMPANY_NAME") }}</dt>
          <dd>
            {{ salesInvoice.billing_company_name }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.COUNTRY") }}</dt>
          <dd>
            {{ salesInvoice.billing_country }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.STATE") }}</dt>
          <dd>
            {{ salesInvoice.billing_state }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.CITY") }}</dt>
          <dd>
            {{ salesInvoice.billing_city }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.ZIPCODE") }}</dt>
          <dd>
            {{ salesInvoice.billing_zipcode }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.ADDRESS") }}</dt>
          <dd>
            {{ salesInvoice.billing_address }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.EMAIL") }}</dt>
          <dd>
            {{ salesInvoice.billing_email }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BILLING_ENTITY_TYPE_INDIVIDUAL,
  BILLING_ENTITY_TYPE_COMPANY,
} from "@/constants/billingInformations";
import SalesInvoiceStatusBadge from "./SalesInvoiceStatusBadge.vue";

export default {
  name: "sales-invoice-view-global",

  components: {
    SalesInvoiceStatusBadge,
  },

  props: ["salesInvoice"],

  data() {
    return {
      BILLING_ENTITY_TYPE_INDIVIDUAL: BILLING_ENTITY_TYPE_INDIVIDUAL,
      BILLING_ENTITY_TYPE_COMPANY: BILLING_ENTITY_TYPE_COMPANY,
    };
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    salesInvoice(salesInvoice) {},
  },
};
</script>
