<template>
  <form
    class="add-form"
    ref="profile_form"
    @submit.prevent="handleSubmit"
    @keydown.enter.prevent="() => {}"
  >
    <div class="add-form-inner">
      <div
        class="form-wrapper"
        v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
      >
        <base-input
          :label="`${$t('COMMON.ORGANIZATION')} (*)`"
          :placeholder="$t('COMMON.ORGANIZATION')"
        >
          <organization-selector
            :allowNone="true"
            :organization="salesInvoice.organization.id"
            :filterable="true"
            :showAll="false"
            :disabled="!!salesInvoice.id"
            @organizationChanged="
              (organizationId) => {
                salesInvoice.organization.id = organizationId;
                salesInvoice.allowedLocations = [];
                salesInvoice.salesOrder.id = null;
                salesInvoice.recipient.id = null;
                onFormChanged();
              }
            "
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.organization" />
      </div>

      <div
        class="form-wrapper"
        v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)"
      >
        <base-input
          :label="`${$t('COMMON.LOCATION')}`"
          :placeholder="$t('COMMON.LOCATION')"
        >
          <locations-selector
            :locations="salesInvoice.allowedLocations"
            :filterable="true"
            :showAll="false"
            :multiple="true"
            :organization="salesInvoice.organization.id"
            @locationsChanged="
              (locations) => {
                salesInvoice.allowedLocations = locations;
                onFormChanged();
              }
            "
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.location" />
      </div>

      <div
        class="form-wrapper"
        v-if="$currentUserCan($permissions.PERM_VIEW_ANY_SALES_ORDERS)"
      >
        <base-input
          :label="`${$t('COMMON.SALES_ORDERS')}`"
          :placeholder="$t('COMMON.SALES_ORDERS')"
        >
          <sales-order-selector
            :allowNone="true"
            :salesOrder="salesInvoice.salesOrder.id"
            :filterable="true"
            :showAll="false"
            :disabled="!!salesInvoice.id"
            :filterOrganization="salesInvoice.organization.id"
            :filterStatus="ORDER_STATUS_VALIDATED"
            @salesOrderChanged="
              (salesOrderId, salesOrder) => {
                salesInvoice.salesOrder.id = salesOrderId;
                if (salesOrder) {
                  salesInvoice.recipient.id = salesOrder.recipient.id;
                  salesInvoice.sourceWarehouse.id =
                    salesOrder.sourceWarehouse?.id;

                  salesInvoice.billing_entity_type =
                    salesOrder.billing_entity_type;
                  salesInvoice.billing_firstname = salesOrder.billing_firstname;
                  salesInvoice.billing_lastname = salesOrder.billing_lastname;
                  salesInvoice.billing_company_name =
                    salesOrder.billing_company_name;
                  salesInvoice.billing_email = salesOrder.billing_email;
                  salesInvoice.billing_country = salesOrder.billing_country;
                  salesInvoice.billing_state = salesOrder.billing_state;
                  salesInvoice.billing_city = salesOrder.billing_city;
                  salesInvoice.billing_zipcode = salesOrder.billing_zipcode;
                  salesInvoice.billing_address = salesOrder.billing_address;
                }
                onFormChanged();
              }
            "
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.salesOrder" />
      </div>

      <div class="form-wrapper">
        <base-input
          :label="`${$t('SALES_INVOICES.EXPIRATION_TIME')} (*)`"
          :placeholder="$t('SALES_INVOICES.EXPIRATION_TIME')"
        >
          <flat-picker
            :config="{
              allowInput: true,
              minDate: 'today',
              locale: $flatPickrLocale(),
            }"
            class="form-control datepicker"
            v-model="salesInvoice.expiration_time"
            @on-change="
              () => {
                onFormChanged();
              }
            "
          >
          </flat-picker>
        </base-input>
        <validation-error :errors="apiValidationErrors.expiration_time" />
      </div>

      <div class="form-wrapper">
        <base-input :label="`${$t(`SALES_INVOICES.SELECT_CUSTOMERS`)} (*)`">
          <customer-selector
            :allowNone="true"
            :customer="salesInvoice.recipient.id"
            :filterable="true"
            :showAll="false"
            :filterOrganization="salesInvoice.organization.id"
            :disabled="!!salesInvoice.id"
            @customerChanged="
              (customerId, customer) => {
                salesInvoice.recipient.id = customerId;
                if (customer) {
                  salesInvoice.billing_entity_type = customer.customer_type;
                  salesInvoice.billing_firstname = customer.firstname;
                  salesInvoice.billing_lastname = customer.lastname;
                  salesInvoice.billing_company_name = customer.company_name;
                  salesInvoice.billing_email = customer.email;
                  salesInvoice.billing_country = customer.country;
                  salesInvoice.billing_state = customer.state;
                  salesInvoice.billing_city = customer.city;
                  salesInvoice.billing_zipcode = customer.zipcode;
                  salesInvoice.billing_address = customer.address;
                }
                onFormChanged();
              }
            "
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.recipient" />
      </div>

      <div class="form-wrapper">
        <base-input :label="`${$t(`COMMON.SOURCE_WAREHOUSE`)}`">
          <warehouse-selector
            :allowNone="true"
            :warehouse="
              salesInvoice.sourceWarehouse
                ? salesInvoice.sourceWarehouse.id
                : null
            "
            :filterable="true"
            :showAll="false"
            :filterOrganization="salesInvoice.organization.id"
            :disabled="!!salesInvoice.id"
            @warehouseChanged="
              (warehouseId) => {
                salesInvoice.sourceWarehouse.id = warehouseId;
                onFormChanged();
              }
            "
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.sourceWarehouse" />
      </div>

      <div class="form-wrapper full">
        <h4>{{ $t("COMMON.NOTE") }}</h4>
        <html-editor v-model="salesInvoice.excerpt" @change="onFormChanged()">
        </html-editor>
        <validation-error :errors="apiValidationErrors.excerpt" />
      </div>

      <div class="form-wrapper title">
        <h2>{{ $t("COMMON.BILLING_INFORMATIONS") }}</h2>
      </div>

      <div class="form-wrapper full">
        <base-input
          :label="$t('COMMON.TYPE')"
          :placeholder="$t('COMMON.TYPE')"
          @change="onFormChanged"
        >
          <el-select name="Type" v-model="salesInvoice.billing_entity_type">
            <el-option
              :value="BILLING_ENTITY_TYPE_INDIVIDUAL"
              :label="$t('COMMON.BILLING_ENTITY_TYPE_INDIVIDUAL')"
            />
            <el-option
              :value="BILLING_ENTITY_TYPE_COMPANY"
              :label="$t('COMMON.BILLING_ENTITY_TYPE_COMPANY')"
            />
          </el-select>
        </base-input>
        <validation-error :errors="apiValidationErrors.billing_entity_type" />
      </div>

      <div
        class="form-group-wrapper"
        v-if="
          salesInvoice.billing_entity_type == BILLING_ENTITY_TYPE_INDIVIDUAL
        "
      >
        <div class="form-group-wrapper-item">
          <base-input
            :label="$t('COMMON.FIRSTNAME')"
            v-model="salesInvoice.billing_firstname"
            @change="onFormChanged"
          />
          <validation-error :errors="apiValidationErrors.billing_firstname" />
        </div>

        <div class="form-group-wrapper-item">
          <base-input
            :label="$t('COMMON.LASTNAME')"
            v-model="salesInvoice.billing_lastname"
          />
          <validation-error :errors="apiValidationErrors.billing_lastname" />
        </div>
      </div>

      <div
        class="form-wrapper"
        v-if="salesInvoice.billing_entity_type == BILLING_ENTITY_TYPE_COMPANY"
      >
        <base-input
          :label="$t('COMMON.COMPANY_NAME')"
          v-model="salesInvoice.billing_company_name"
          @change="onFormChanged"
        />
        <validation-error :errors="apiValidationErrors.billing_company_name" />
      </div>

      <div class="form-wrapper">
        <base-input
          :label="$t('COMMON.EMAIL')"
          v-model="salesInvoice.billing_email"
          @change="onFormChanged"
        />
        <validation-error :errors="apiValidationErrors.billing_email" />
      </div>

      <div class="form-wrapper">
        <base-input
          :label="$t('COMMON.COUNTRY')"
          :placeholder="$t('COMMON.COUNTRY')"
        >
          <country-selector
            :country="salesInvoice.billing_country"
            :filterable="true"
            :showAll="false"
            @countryChanged="
              (country) => {
                salesInvoice.billing_country = country;
                onFormChanged();
              }
            "
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.billing_country" />
      </div>

      <div class="form-wrapper">
        <base-input
          :label="$t('COMMON.STATE')"
          :placeholder="$t('COMMON.STATE')"
        >
          <state-selector
            :country="salesInvoice.billing_country"
            :state="salesInvoice.billing_state"
            :filterable="true"
            :showAll="false"
            @stateChanged="
              (state) => {
                salesInvoice.billing_state = state;
                onFormChanged();
              }
            "
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.billing_state" />
      </div>

      <div class="form-wrapper">
        <base-input
          :label="$t('COMMON.CITY')"
          v-model="salesInvoice.billing_city"
          @change="onFormChanged"
        />
        <validation-error :errors="apiValidationErrors.billing_city" />
      </div>

      <div class="form-wrapper">
        <base-input
          :label="$t('COMMON.ZIPCODE')"
          v-model="salesInvoice.billing_zipcode"
          @change="onFormChanged"
        />
        <validation-error :errors="apiValidationErrors.billing_zipcode" />
      </div>

      <div class="form-wrapper">
        <base-input
          :label="$t('COMMON.ADDRESS')"
          v-model="salesInvoice.billing_address"
          @change="onFormChanged"
        />
        <validation-error :errors="apiValidationErrors.billing_address" />
      </div>
    </div>

    <div class="submit-wrapper">
      <base-button
        type="button"
        class="btn btn-sm elite-submit"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading"></i>
        {{
          salesInvoice.id
            ? $t("SALES_INVOICES.EDIT_SALES_INVOICE")
            : $t("SALES_INVOICES.ADD_SALES_INVOICE")
        }}
      </base-button>
    </div>
  </form>
</template>
<script>
import moment from "moment";
import { cloneDeep } from "lodash";
import { Select, Option } from "element-ui";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import {
  BILLING_ENTITY_TYPE_INDIVIDUAL,
  BILLING_ENTITY_TYPE_COMPANY,
} from "@/constants/billingInformations";
import { ORDER_STATUS_VALIDATED } from "@/constants/orders";
import formMixin from "@/mixins/form-mixin";
import CountrySelector from "@/components/CountrySelector.vue";
import StateSelector from "@/components/StateSelector.vue";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import SalesOrderSelector from "@/components/SalesOrderSelector.vue";
import LocationsSelector from "@/components/LocationsSelector.vue";
import CustomerSelector from "@/components/CustomerSelector.vue";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import WarehouseSelector from "@/components/WarehouseSelector.vue";

export default {
  layout: "DashboardLayout",

  components: {
    BaseInput,
    ValidationError,
    OrganizationSelector,
    LocationsSelector,
    flatPicker,
    HtmlEditor,
    CustomerSelector,
    SalesOrderSelector,
    CountrySelector,
    StateSelector,
    WarehouseSelector,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: ["salesInvoiceData", "formErrors", "loading"],

  data() {
    let salesInvoiceData = { ...this.salesInvoiceData };
    salesInvoiceData = this.$fillUserOrganizationData(salesInvoiceData);

    return {
      salesInvoice: salesInvoiceData,
      ORDER_STATUS_VALIDATED: ORDER_STATUS_VALIDATED,
      BILLING_ENTITY_TYPE_INDIVIDUAL: BILLING_ENTITY_TYPE_INDIVIDUAL,
      BILLING_ENTITY_TYPE_COMPANY: BILLING_ENTITY_TYPE_COMPANY,
    };
  },

  created() {},

  methods: {
    async handleSubmit() {
      let salesInvoiceData = cloneDeep(this.salesInvoice);
      salesInvoiceData.expiration_time = moment(
        salesInvoiceData.expiration_time
      ).toISOString();
      salesInvoiceData = this.$fillUserOrganizationData(salesInvoiceData);
      if (!salesInvoiceData.salesOrder.id) {
        delete salesInvoiceData.salesOrder;
      }
      if (!salesInvoiceData.billing_company_name) {
        salesInvoiceData.billing_company_name = "N/A";
      }
      if (!salesInvoiceData.billing_firstname) {
        salesInvoiceData.billing_firstname = "N/A";
      }
      if (!salesInvoiceData.billing_lastname) {
        salesInvoiceData.billing_lastname = "N/A";
      }
      this.$emit("salesInvoiceSubmitted", salesInvoiceData);
    },

    onFormChanged() {
      this.$emit("formChanged");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    salesInvoiceData(salesInvoiceData) {
      if (salesInvoiceData) {
        this.salesInvoice = {
          ...this.salesInvoice,
          ...cloneDeep(salesInvoiceData),
        };
        if (!this.salesInvoice.organization) {
          this.salesInvoice.organization = {
            type: "organizations",
            id: null,
          };
        }
      }
    },
  },
};
</script>
